import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { breakpointQuery, sectionMargins } from '../utilities';
import Container from './Container';
import SectionHeader from './SectionHeader';
import Card from './Card';

const StyledCards = styled.section`
  ${sectionMargins()};
`;

const StyledCardsInner = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 20px;

  ${breakpointQuery.tsmall`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpointQuery.small`
    margin-top: 30px;
  `}

  ${breakpointQuery.mlarge`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${breakpointQuery.large`
    margin-top: 40px;
  `}
`;

const Cards = ({
  data: {
    allDatoCmsNewsInsight: { nodes },
  },
  heading,
  text,
  linkText,
  linkUrl,
  items,
  baseSlug = '',
}) => {
  const cards = items && items.length > 0 ? items : nodes;

  return (
    <StyledCards>
      {heading && (
        <SectionHeader
          heading={heading}
          text={text}
          linkText={linkText}
          linkUrl={linkUrl}
        />
      )}
      <Container>
        <StyledCardsInner>
          {cards.map(card => (
            <Card key={card.id} data={card} baseSlug={baseSlug} />
          ))}
        </StyledCardsInner>
      </Container>
    </StyledCards>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query CardsQuery {
        allDatoCmsNewsInsight(
          sort: { fields: meta___firstPublishedAt, order: DESC }
          limit: 4
        ) {
          nodes {
            id
            title
            slug
            featuredImage {
              fluid(
                maxHeight: 225
                maxWidth: 400
                imgixParams: {
                  auto: "compress"
                  fit: "crop"
                  h: "225"
                  w: "400"
                }
              ) {
                ...GatsbyDatoCmsFluid
              }
              alt
            }
            meta {
              firstPublishedAt(formatString: "DD/MM/YYYY")
            }
          }
        }
      }
    `}
    render={data => <Cards data={data} {...props} />}
  />
);
