import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  breakpointSizes,
  standardColours,
  brandColours,
  fluidFontSize,
} from '../utilities';
import Animation from './Animation';
import ButtonLink from './ButtonLink';

const StyledCard = styled.article`
  height: 100%;
  border: 1px solid ${standardColours.lighterGrey};
`;

const StyledCardContent = styled.div`
  padding: 30px;
`;

const StyledCardImage = styled(Img)``;

const StyledCardHeading = styled.h3`
  margin-bottom: 20px;
  ${fluidFontSize('16px', '22px', breakpointSizes.tiny, breakpointSizes.large)}

  &:before {
    content: '';
    display: block;
    margin-bottom: 20px;
    height: 4px;
    width: 60px;
    background-color: ${brandColours.tertiary};
  }
`;

const Card = ({ data: { title, video, featuredImage, slug }, baseSlug }) => {
  return (
    <Animation>
      <StyledCard>
        {video && video.video && video.video.thumbnailUrl ? (
          <img src={video.video.thumbnailUrl} alt={video.alt} />
        ) : (
          <StyledCardImage
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
          />
        )}
        <StyledCardContent>
          <StyledCardHeading>{title}</StyledCardHeading>
          <ButtonLink to={baseSlug + slug + '/'}>Read more</ButtonLink>
        </StyledCardContent>
      </StyledCard>
    </Animation>
  );
};

export default Card;
