import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import Banner from '../../components/Banner';
import Cards from '../../components/Cards';

const DemosWebinarsPage = ({
  data: {
    datoCmsBannerImage: { demosWebinarsBannerImage },
    allDatoCmsDemoWebinar: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms title="Demos &amp; Webinars | Barcanet" />
    <main>
      <Banner heading="Demos &amp; Webinars" image={demosWebinarsBannerImage} />
      <Cards items={nodes} baseSlug="/resources/demos-webinars/" />
    </main>
  </Layout>
);

export const DemosWebinarsPageQuery = graphql`
  query DemosWebinarsPageQuery {
    datoCmsBannerImage {
      demosWebinarsBannerImage {
        ...BannerImageFragment
      }
    }
    allDatoCmsDemoWebinar(
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        video {
          video {
            thumbnailUrl(format: jpg)
          }
          alt
        }
        featuredImage {
          fluid(
            maxHeight: 225
            maxWidth: 400
            imgixParams: { auto: "compress", fit: "crop", h: "225", w: "400" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        meta {
          firstPublishedAt(formatString: "DD/MM/YYYY")
        }
      }
    }
  }
`;

export default DemosWebinarsPage;
